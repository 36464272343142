import React, { useEffect } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleChallan, getVehicleDetails } from "../../store/actions/TripsAction";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function AllFastagVehicles(props) {
  const {vrn} = props;
  const { state } = useLocation();
  const dispatch = useDispatch();

  const {vehicleChallan} = useSelector(state => state?.trip)
  console.log("jsrfjr",vehicleChallan)
  const navigate = useNavigate()


  useEffect(() => {
    console.log("sjrofojrs")
    if(state?.vrn){
      dispatch(getVehicleChallan({vrn : state?.vrn},(resp) => {
      }))
    }
  },[])


  const {allFastagVehcile,loader} = useSelector(state => state?.trip)
  console.log("enmkrjie",allFastagVehcile)
  // Disposed_data
  return (
    <div className="">
      <Header /> 
      <div 
        className="d-flex flex-column"
        style={{ margin: "50px 0 0 0", gap: "10px" }}
      >
        <div className="FontNB24">{state?.vrn}</div>
        <div className="FontNB24">All Your Vehicles</div>
      </div>
      <div className="txnDetalis" style={{ marginTop: "30px" }}>
        {loader ? <div> Loading...</div> : !allFastagVehcile ? <Navigate to = "/truckRecord" /> : allFastagVehcile?.length < 1 ? <></> : allFastagVehcile?.map((item,idx) => {
          return(
            <div className="" style={{border: "1px solid rgba(0, 0, 0, 0.2)",cursor: "pointer"}} onClick={() => {
                dispatch(getVehicleDetails({vrn : item?.vrn},resp => {
                    console.log("smekfjoer",resp)
                    if(resp?.status == 200){
                        navigate("/truckRecord",{state : {vrn : item?.vrn}})
                    }
                  }))
            }}>
            <div className="" style={{display: "flex",    width: "100%",
    justifyContent: "center"}}>
              <div className="font_19" style={{textAlign : "center"}}>{item?.vrn}</div>
              {/* <div className="fastagBoxDesc">{item?.challan_date_time} || {item?.challan_place}</div> */}
            </div>
          </div>
          )
        })}
      </div>
    </div>
  );
}

export default AllFastagVehicles;
